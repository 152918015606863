import toast from '../../state/modules/toast';

const validateObjectMixin = {
    data() {
        return {
          validateItems: [],
        }
      },
      methods: {
        validateObjectData(data) {
          this.validateItems = []
          // eslint-disable-next-line no-restricted-syntax
          for (const [key, value] of Object.entries(data).reverse()) {
            // eslint-disable-next-line no-console
            const newKey = key.replaceAll('_', ' ');
            if (value === '' || value === null ) {
                toast(`This ${newKey} is required !`, 'error', 'center')
              this.validateItems.push(key)
            }
          }
    
          return this.validateItems
        },
        validateCustomInputItems(data, field, name) {
            const incompleteItems = data.filter(elm => !elm[field]); // Simplified filtering condition
        
            if (incompleteItems.length > 0) {
                const errorMessage = `Complete all ${name} fields!`;
                toast(errorMessage, 'error', 'center');
            }
        
            // eslint-disable-next-line no-console
            console.log(incompleteItems);
            return incompleteItems; // Return only the incomplete items for further processing if needed
        }
      },

}

export default validateObjectMixin