<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import { authUserMethods } from "../../../state/helpers/authuser"
import {
  required,
} from "vuelidate/lib/validators";
import { configurationMethods } from "../../../state/helpers/configuration";
import Swal from "sweetalert2";
import settingIcon from "../../../assets/images/icons/settings.png";
import validateObjectMixin  from "../../mixins/validateObjectMixin";
/**
 * Blank page component
 */
export default {
  page: {
    title: "App Configurations",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout },
  mixins: [validateObjectMixin],
  data() {
    return {
      settingIcon,
      configuration: {
        name: '',
        data: [],
      },
      configurations: [],
      payment_type: ["physical", "electronic"],
      title: "Configuration Settings",
      is_update: false,
      submitted: false,
    };
  },
  validations: {
    configuration: {
      name: { required },
    },
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    }
  },
  mounted() {
    this.getAllConfiguration()
    // Set the initial number of items
    // this.totalRows = this.items?.length;
  },
  methods: {
    ...authUserMethods,
    ...configurationMethods,
    
    getAllConfiguration() {
      return this.allConfiguration({ paginate: true }).then((response) => {
        // eslint-disable-next-line no-console
        this.configurations = response.data.data
        this.totalRows = response.data.total
        
      })
    },
    searchData(event) {
      // eslint-disable-next-line no-console
      const value = event.target.value
      return this.allConfiguration({ paginate: true, keyword: value }).then((response) => {
        // eslint-disable-next-line no-console
        this.configurations = response.data.data
        this.totalRows = response.data.total
      })
    },
    showModal() {
      this.$bvModal.show('app-modal')
    },
    hide() {
      this.$bvModal.hide('app-modal')
      this.is_update = false;
      this.emptyData()
    },
    showDetails(item) {
      /* eslint-disable */ 
      const configDataObject = JSON.parse(item.data)
      const dynamicConfigDataForUpdate = Object.entries(configDataObject).map(([key, value]) => ({name: key, value}))
      this.configuration = {id: item.id, name: item.name, data: dynamicConfigDataForUpdate}
      this.is_update = true;
      this.showModal()
    },
    handleSaveConfiguration() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      const dataIncompleteValue = this.validateCustomInputItems(this.configuration.data, 'name', 'data')
      if(dataIncompleteValue.length > 0) return;
      if (this.$v.$invalid) {
        return;
      } else {

        const config = this.configuration.data.map((item) => ({[item.name]: item.value}))
        const config_object = Object.assign({}, ...config);
        const data = { name: this.configuration.name, data: config_object}
        this.createConfiguration(data).then(() => {
          this.hide()
          this.getAllConfiguration()
        })
      }
      this.submitted = false;
    },
    handleUpdateConfiguration() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      const dataIncompleteValue = this.validateCustomInputItems(this.configuration.data, 'name', 'data')
      if(dataIncompleteValue.length > 0) return;
      if (this.$v.$invalid) {
        return;
      } else {
        const config = this.configuration.data.map((item) => ({[item.name]: item.value}))
        const config_object = Object.assign({}, ...config);
        const data = { id:this.configuration.id,  name: this.configuration.name, data: config_object}
        this.updateConfiguration(data).then(() => {
          this.hide()
          this.getAllConfiguration()
        })
      }
      this.submitted = false;
    },
    emptyData() {
      this.configuration = {
        name: "",
        data: [],
      }
    },
    handleDeleteConfiguration() {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes, delete it!"
      }).then(result => {
        if (result.value) {
          this.deleteConfiguration(this.configuration.id).then(() => {
            this.getAllConfiguration()
            this.hide()
          })
        }
      });
    },

    handleAddData() {
      this.configuration.data.push({name: "", value: ""})
    },
    handleRemoveData(index){
       this.configuration.data.splice(index, 1)
    },
  }
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-md-6">
        <b-card no-body class="mt-5">
      <b-card-body>
        <b-card-title class="d-flex mb-3 align-items-center justify-content-between">
          <h1 class="card-title">App Configurations List</h1>
          <a href="javascript:void(0);" class="btn btn-outline-success btn-block" @click="showModal">Add New</a>
        </b-card-title>
        <b-card-title class="d-flex mb-3 align-items-center justify-content-between">
    <div class="row" style="flex: 1">
      <div class="col-md-8">
        <label class="form-label" for="validationCustom04">Search for Configurations</label>
        <input id="validationCustom04"  type="text" class="form-control" @input="searchData"/>
      </div>
    </div>
</b-card-title>
        <b-card-text>
         <div class="row mt-5">
          <div class="col-md-4 mb-3" v-for="(item, index) in configurations" :key="index" @click="showDetails(item)">
            <div class="configuration_card">
              <img :src="settingIcon" alt="settings" />
              <h5>{{ item.name }}</h5>
            </div>
          </div>
         </div>
        </b-card-text>



        <!-- user modal -->
        <b-modal id="app-modal" :title="is_update ? 'Update Configuration' : 'Add Configuration'"
          title-class="font-18" size="lg" hide-footer @hide="hide">
          <div class="row">

            <div class="col-md-12">
              <!-- <h4 class="mb-3 mt-3">Infomation</h4> -->
              <form class="needs-validation">
                <div class="row">

                  <div class="col-md-12">
                    <div class="mb-4">
                      <label class="form-label" for="validationCustom04">Configurations Name</label>
                      <input id="validationCustom04" v-model="configuration.name" type="text" class="form-control"
                        placeholder="" :class="{
                          'is-invalid': submitted && $v.configuration.name.$error,
                        }" />
                      <div v-if="submitted && $v.configuration.name.$error" class="invalid-feedback">
                        <span v-if="!$v.configuration.name.required">This name is required.</span>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-12 mt-2 mb-5">
                    <div class="d-flex align-items-center justify-content-between mb-5">
                        <h4>Add Data</h4>
                        <button type="button" class="btn btn-outline-success icon-btn" @click="handleAddData"><i
                            class="ion ion-md-add" style="font-size: 20px"></i></button>
                      </div>

                      <div class="row mb-3" v-for="(data, index) of configuration.data" :key="index">
                         <div class="col-md-4">
                          
                          <input id="validationCustom04" v-model="data.name" type="text" class="form-control"
                        placeholder="Data Name" />
                         </div>
                         <div class="col-md-6">
                       
                          <input id="validationCustom04" v-model="data.value" type="text" class="form-control"
                        placeholder="Data Value" />
                         </div>
                         <div class="col-md-2">
                          <button type="button" class="btn btn-outline-danger icon-btn" @click="handleRemoveData(index)"><i
                            class="ion ion-ios-trash" style="font-size: 20px"></i></button>
                         </div>
                      </div>
                  </div>

                </div>
                <div class="button_view d-flex align-items-center justify-content-between mt-4"
                 >
                  <button type="button" class="btn btn-outline-secondary" @click="hide">Cacel</button>
                  <button type="button" v-if="is_update" class="btn btn-outline-danger"
              @click="handleDeleteConfiguration">Delete</button>
                  <button type="button" class="btn btn-outline-success"
                    @click="is_update ? handleUpdateConfiguration() : handleSaveConfiguration()">Save</button>
                </div>
              </form>
            </div>
          </div>
        </b-modal>

      </b-card-body>
    </b-card>
      </div>
    </div>

  </Layout>
</template>